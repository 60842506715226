<template>
  <div class="flex flex-col items-center py-4">
    <upload-client-file-form
      :show-checklist-config="true"
      :show-print-config="true" />
  </div>
</template>
<script>
export default {
  name: 'UploadClientData',
  components: {
    UploadClientFileForm: () => import('@/components/forms/UploadClientFileForm.vue'),
  },
}
</script>
